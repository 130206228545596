<template>
  <div>
    <tabs :tabList="tabList" @active-tab="activeTabName = $event" />

    <keep-alive>
      <component :is="`Dashboard${activeTabName}`" />
    </keep-alive>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Tabs from '@/components/Tabs'
import DashboardJoinUsers from './DashboardJoinUsers'
import DashboardTokens from './DashboardTokens'

export default {
  data() {
    return {
      activeTabName: 'JoinUsers',
      tabList: [
        { id: 1, name: 'JoinUsers', title: 'staking users' },
        { id: 2, name: 'Tokens', title: 'staking toko' }
      ]
    }
  },

  components: {
    Tabs,
    DashboardJoinUsers,
    DashboardTokens
  },

  methods: {
    ...mapActions('staking', ['getStakingPlans'])
  },

  async created() {
    await this.getStakingPlans()
  }
}
</script>
